import axios from 'axios'
import { ModelSelectorModel } from 'components/Utils/ModelSelector'
import { fullTimeBlock } from 'components/Utils/fullTimeBlock'
import { emptyTimeBlock } from 'components/Utils/emptyTimeBlock'
import { TimeDropDownHourMinute } from 'components/Utils/TimeDropDownHourMinute'
import { RecordNotFoundError } from 'FourOhFourNotFound'

import {
  Dialect,
  DisplayConfiguration,
  Player,
  Location,
  Network,
  MediaGroup,
  Tag,
  TagGroup,
  Tagging,
  Font,
  ApiKey,
  CustomField,
  CustomFieldValue,
  PlayerBulkAction,
  BinAssignment,
  ReportRequest,
  GroupCategory,
  Customer,
  SmartGroup,
  SmartGroupCondition,
  SpotSwapItem,
  TimeRange,
  FeedItem,
  MediaGroupItem,
  DisplayModelVendor,
  Presentation,
  PlayerGroup,
  ContentExclusion,
  CustomizedTimeRange,
  GranularPermission,
  PlayerDistribution,
  User,
  Preference,
  Subtitle,
  CurrentUser,
  Log,
  Media,
  ApplicationRecord,
  GroupCategoryCapable,
  CustomFieldValueCapable,
  ResetPasswordRequest,
  ResetPassword,
  MediaBulkAction,
  SmartGroupCapableModels,
  DisplayConfigurationAttributes,
  DuplicatePresentation,
  PresentationEditTracking,
  GranularPermissionTypes,
  LocationBulkAction,
  NetworkBulkAction,
} from 'neocast-api-js-client'
import { presentationDetailsPath } from 'Routes'
import { CollectionProxy, RecordProxy } from 'spraypaint/lib-esm/proxies'
import { dummyIdGenerator } from 'utils'
import { X_NO_CLIENT_TIMEOUT_REFRESH_HEADER } from 'utils/loggedInStatus'
import { jsonToErrorId } from 'utils/spraypaint-middleware'
import { translateRaw } from 'components/Utils/translateRaw'

export type HandleApiResponse<T> = (success: boolean, record: T, onNavigate?: () => void) => void

export interface MetaData {
  stats: {
    total: {
      count: number
    }
  }
}

// Helper function to extract the data from an API response and return it in a promise that can be handled
// by the calling code. This undoes an approach where we were passing a dispatch method.

function dataAndCountPromise<T>(response: CollectionProxy<T extends ApplicationRecord ? T : any>) {
  return Promise.resolve<[T[], number]>([response.data, response.meta?.stats?.total?.count])
}

function handleRecord<T>(response: RecordProxy<T extends ApplicationRecord ? T : any>) {
  return Promise.resolve<[T, number]>([response.data, response.meta?.stats?.total?.count])
}

async function handleException(error: any) {
  console.log(error)

  if (error.response) {
    if (error.response.status === 404) {
      throw new RecordNotFoundError()
    }

    const json = await error.response.clone().json()

    const id = jsonToErrorId(json)

    console.log('%c%s', 'color: red; font-size: 1.3rem;', `NEOCAST ERROR ${error.message} -- ID ${id}`)
  }

  return []
}

export type FolderSupportingClasses = 'Media' | 'Player' | 'Presentation'

export const newGroupCategory = () => new GroupCategory({ modelClassName: 'GroupCategory' })

export const createGroupCategory = ({ name, targetClass }: { name: string; targetClass: string }) => {
  const groupCategory = new GroupCategory()
  groupCategory.name = name
  groupCategory.targetClass = targetClass
  groupCategory.canUpdate = true

  return groupCategory.save().then(success => Promise.resolve([success, groupCategory]))
}

export const destroyGroupCategory = ({ groupCategory }: { groupCategory: GroupCategory }) => {
  return groupCategory.destroy().then(success => Promise.resolve([success, groupCategory]))
}

export const updateGroupCategory = ({ groupCategory }: { groupCategory: GroupCategory }) => {
  return groupCategory.save().then(success => Promise.resolve([success, groupCategory]))
}

export const moveObjectsToCategory = async ({
  objects,
  categoryId,
}: {
  objects: (ApplicationRecord & GroupCategoryCapable)[]
  categoryId: string | null
}) => {
  const promises = objects.map(function (object) {
    object.groupCategoryId = categoryId

    return object.save()
  })

  await Promise.all(promises)
}

export const searchMediaByFileName = ({ fileName }: { fileName: string }) => {
  let query = Media.where({ partial_file_name: { match: fileName } }).select(['name', 'type', 'id', 'fileName'])

  return query.all().then(response => dataAndCountPromise<Media>(response))
}

export const buildPresentation = (
  name: string,
  displayConfiguration: DisplayConfigurationAttributes | undefined
): Presentation => {
  if (displayConfiguration === undefined) {
    return new Presentation({ name })
  }

  const [screenResolutionX, screenResolutionY] =
    displayConfiguration.orientation.toUpperCase() === 'LANDSCAPE'
      ? [displayConfiguration.screenResolutionX, displayConfiguration.screenResolutionY]
      : [displayConfiguration.screenResolutionY, displayConfiguration.screenResolutionX]

  const [screenArrayX, screenArrayY] = [displayConfiguration.screenArrayX, displayConfiguration.screenArrayY]

  return new Presentation({
    name,
    displayConfigurationId: displayConfiguration.id,
    presentationJson: JSON.stringify({
      name,
      continuations: [],
      layouts: [
        {
          key_zone_id: 1,
          id: 1,
          label: translateRaw('new-layout-name', { number: 1 }),
          boilerplate: 1,
          zones: [
            {
              width: screenResolutionX,
              height: screenResolutionY,
              id: 1,
              x: 0,
              y: 0,
              z_order: 1,
              opacity: 100,
              hidden: 0,
              aspect_ratio: 0,
              transparency: 0,
              contents: [],
            },
          ],
        },
      ],
      size: {
        screen: { x: screenResolutionX, y: screenResolutionY },
        array: { x: screenArrayX, y: screenArrayY },
        orientation: displayConfiguration.orientation.toUpperCase(),
      },
    }),
  })
}

export const presentationNameExists = ({ name }: { name: string }) => {
  return Presentation.where({ name })
    .all()
    .then(response => {
      if (response.data.length > 0) {
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    })
    .catch(() => Promise.resolve(false))
}

export const createPresentationEditTracking = async ({
  presentation,
  details,
}: {
  presentation: Presentation
  details: string
}) => {
  const tracking = new PresentationEditTracking()
  tracking.details = details
  tracking.presentationId = presentation.key()

  await tracking.save()
}

export const savePresentation = ({
  presentation,
  handleApiResponse,
}: {
  presentation: Presentation
  handleApiResponse: HandleApiResponse<Presentation>
}) => {
  presentation
    .save()
    .then(success => handleApiResponse(success, presentation))
    .catch(handleException)
}

export const loadPresentationDetails = ({ id }: { id: string }) => {
  return axios.post(presentationDetailsPath(), { presentation_id: id })
}

export const loadPresentationDetailsForIds = ({
  mediaIds,
  mediaGroupIds,
  smartGroupIds,
}: {
  mediaIds: number[]
  mediaGroupIds: number[]
  smartGroupIds: number[]
}) => {
  return axios.post(presentationDetailsPath(), {
    media_ids: mediaIds,
    media_group_ids: mediaGroupIds,
    smart_group_ids: smartGroupIds,
  })
}

export const loadPresentation = ({ id, withJson = false }: { id: string; withJson?: boolean }) => {
  let fields = [
    'can_update',
    'can_delete',
    'updated_by_name',
    'duration',
    'network_ids_and_names',
    'location_ids_and_names',
    'player_ids_and_names',
    'smart_group_ids_and_names',
    'player_group_ids_and_names',
  ]

  if (withJson) {
    fields = [...fields, 'presentation_json']
  }

  return Presentation.selectExtra(fields)
    .includes(['plugins', 'time_range', { tags: ['tag_group'] }])
    .find(id)
    .then(response => handleRecord<Presentation>(response))
    .catch(handleException)
}

export const loadFonts = () => Font.all().then(response => dataAndCountPromise<Font>(response))

export const loadCustomFields = ({ model }: { model: string }) =>
  CustomField.where({ model })
    .all()
    .then(response => dataAndCountPromise<CustomField>(response))

export const loadSpecificMedia = ({
  id,
  type,
  ignoreClientTimeout = false,
}: {
  id: string | number
  type?: string
  ignoreClientTimeout?: boolean
}) => {
  if (id === 'new') {
    const media = new Media({
      type,
      ...{
        audioCodec: '',
        audioEncodeRateKilobitsPerSecond: 0,
        audioSampleRateKilobitsPerSecond: 0,
        backgroundImageFileName: '',
        cachedFileSize: 0,
        cachedMd5sum: '',
        cachingFrequency: 0,
        colorspace: '',
        description: '',
        displaySpeed: 0,
        displayStyle: '',
        feedBackgroundColor: '',
        feedFontSize: 12,
        feedForegroundColor: '',
        feedHorizontalAlignment: '',
        feedHorizontalPad: 0,
        feedItemsCount: 0,
        feedTickerType: '',
        feedVerticalAlignment: '',
        feedVerticalPad: 0,
        fileName: '',
        height: 0,
        httpCallbackCompleteUrl: '',
        httpCallbackCompleteVerb: '',
        httpCallbackFailureUrl: '',
        httpCallbackFailureVerb: '',
        httpCallbackProgressUrl: '',
        httpCallbackProgressVerb: '',
        httpCallbackStartingUrl: '',
        httpCallbackStartingVerb: '',
        isDirectorSpot: false,
        isSpotSwap: false,
        isZipCodeEnabled: false,
        localFeed: false,
        name: '',
        needsPreviewGenerated: false,
        needsThumbnailGenerated: false,
        newFileName: '',
        newFileId: '',
        noLongerPlayableAt: undefined,
        playableAt: undefined,
        remoteUrl: '',
        rssIconFileName: '',
        runTime: 0,
        runTimeMs: 0,
        screenPosition: '',
        thumbnailFrameNumber: '',
        thumbnailSpotSwapItemId: 0,
        thumbnailSrc: '',
        videoCodec: '',
        videoEncodeRateMegabitsPerSecond: 0,
        videoFramesPerSecond: 0,
        volume: 100,
        webContentControlsDuration: false,
        width: 0,
        feedFrameRate: 20,
        feedMessageScrollRate: 6,
        duration: 10,
        refreshRate: 240,
        custom: true,
        logable: true,
        allPresentations: [],
        spotSwapItems: [],
        tags: [],
        customFieldValues: [],
        canUpdate: true,
      },
    })

    return Promise.resolve<[Media, number]>([media, 1])
  }

  return Media.extraFetchOptions(ignoreClientTimeout ? { headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER } : {})
    .includes([
      'spot_swap_items',
      'feed_background_image',
      'linked_media',
      'feed_items',
      'feed_icon',
      { tags: ['tag_group'] },
      { custom_field_values: 'custom_field', subtitles: 'dialect' },
    ])
    .selectExtra({
      media: ['can_update', 'can_delete', 'updated_by_name', 'all_presentations', 'linked_media_name', 'feed_icon'],
      spot_swap_items: ['download_url', 'distributable_description'],
    })
    .find(id)
    .then(response => handleRecord<Media>(response))
    .catch(handleException)
}

export const fetchThumbnail = ({ url }: { url: string }) =>
  axios.post(url).then(({ data: { ok, uri } }: { data: { ok: boolean; uri: string } }) => {
    const results: [string, number] = ok ? [uri, 1] : ['', 0]

    return Promise.resolve<[string, number]>(results)
  })

export const newSubtitle = ({
  media,
  newUploadId,
  dialect,
}: {
  media: Media
  newUploadId: string
  dialect: ModelSelectorModel
}) => {
  const subtitle = new Subtitle()
  subtitle.temp_id = dummyIdGenerator()
  subtitle.media = media
  subtitle.mediaId = media.id
  subtitle.dialect = new Dialect(dialect)
  subtitle.dialectId = dialect.id
  subtitle.newUploadId = newUploadId
  subtitle.canUpdate = true

  return subtitle
}

export const saveMedia = ({
  media,
  handleApiResponse,
}: {
  media: Media
  handleApiResponse: HandleApiResponse<Media>
}) => {
  media
    .save({
      with: ['customFieldValues', 'spotSwapItems', 'subtitles', 'feedItems', 'mediaLink'],
    })
    .then(success => handleApiResponse(success, media))
    .catch(handleException)
}

export const destroyMedia = ({
  media,
  handleApiResponse,
}: {
  media: Media
  handleApiResponse: HandleApiResponse<Media>
}) => {
  media
    .destroy()
    .then(success => handleApiResponse(success, media))
    .catch(handleException)
}

export const newFeedItem = () => {
  // Ensure we have a blank title and description to avoid
  // an issue with an input considering itself 'uncontrolled'
  const newItem = new FeedItem({ position: 0, title: '', description: '', modelClassName: 'FeedItem', canUpdate: true })
  newItem.temp_id = dummyIdGenerator()
  return newItem
}

export const newSpotSwapItem = ({
  spot_swap_item,
}: {
  spot_swap_item: {
    id: string
    file_name: string
    distributable_id: string
    distributable_type: string
    download_url: string
    distributable_description: string
  }
}) => {
  const newSpotSwapItem = new SpotSwapItem({
    id: spot_swap_item.id,
    modelClassName: 'SpotSwapItem',
    fileName: spot_swap_item.file_name,
    distributableId: spot_swap_item.distributable_id,
    distributableType: spot_swap_item.distributable_type,
    downloadUrl: spot_swap_item.download_url,
    distributableDescription: spot_swap_item.distributable_description,
    canUpdate: true,
  })

  // Set an additional attribute to style this item differently (highlighting that it's new)
  newSpotSwapItem.newlyCreated = true

  // Mark it as persisted since we only wish to track *changes* from this point forward
  newSpotSwapItem.isPersisted = true

  return newSpotSwapItem
}

export const loadOneUser = ({ id }: { id: string }) =>
  User.selectExtra({
    users: ['can_update', 'can_delete', 'role_names', 'updated_by_name'],
    granular_permissions: ['permissable_type', 'permissable_name'],
  })
    .includes(['granular_permissions', { custom_field_values: ['custom_field'] }])
    .find(id)
    .then(response => handleRecord<User>(response))
    .catch(handleException)

export const createApiKey = () => {
  const apiKey = new ApiKey()

  return apiKey.save().then(success => Promise.resolve<[boolean, ApiKey]>([success, apiKey]))
}

export const destroyApiKey = ({
  apiKey,
  handleApiResponse,
}: {
  apiKey: ApiKey
  handleApiResponse: HandleApiResponse<ApiKey>
}) =>
  apiKey
    .destroy()
    .then(success => handleApiResponse(success, apiKey))
    .catch(handleException)
    .catch(handleException)

export const loadOnlyCustomer = () => {
  return Customer.find(0)
    .then(response => handleRecord<Customer>(response))
    .catch(handleException)
}

export const loadCustomer = () => {
  return Customer.includes(['api_keys', 'time_ranges', 'custom_fields', { tagGroups: 'tags' }])
    .find(0)
    .then(response => handleRecord<Customer>(response))
    .catch(handleException)
}

export const saveCurrentUser = ({
  user,
  handleApiResponse,
}: {
  user: CurrentUser
  handleApiResponse: HandleApiResponse<CurrentUser>
}) => {
  user
    .save()
    .then(success => handleApiResponse(success, user))
    .catch(handleException)
}

export const saveCustomer = ({
  customer,
  handleApiResponse,
}: {
  customer: Customer
  handleApiResponse: HandleApiResponse<Customer>
}) => {
  customer
    .save({
      with: ['customFields', 'timeRanges', { tagGroups: 'tags' }],
    })
    .then(success => {
      handleApiResponse(success, customer)
    })
    .catch(handleException)
}

export const loadUser = ({ id }: { id: string }) => {
  if (id === 'new') {
    const user = new User({ roleNames: [], canUpdate: true })

    return Promise.resolve<[User, number]>([user, 1])
  }

  return loadOneUser({ id: id })
}

export const saveUser = ({ user, handleApiResponse }: { user: User; handleApiResponse: HandleApiResponse<User> }) => {
  user
    .save({ with: ['customFieldValues', 'granularPermissions'] })
    .then(success => {
      handleApiResponse(success, user)
    })
    .catch(handleException)
}

export const destroyUser = ({
  user,
  handleApiResponse,
}: {
  user: User
  handleApiResponse: HandleApiResponse<User>
}) => {
  user
    .destroy()
    .then(success => {
      handleApiResponse(success, user)
    })
    .catch(handleException)
}

export const newGranularPermission = ({ model }: { model: { id: string; class: string; name: string } }) => {
  const granularPermission = new GranularPermission()
  granularPermission.modelClassName = 'GranularPermission'
  granularPermission.temp_id = dummyIdGenerator()
  granularPermission.permissableId = model.id
  granularPermission.permissableType = model.class as GranularPermissionTypes
  granularPermission.permissableName = model.name
  granularPermission.canUpdate = true

  return granularPermission
}

export const loadLocation = ({ id, includeCounts = false }: { id: string; includeCounts?: boolean }) => {
  return Location.extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
    .includes([
      'network',
      { tags: ['tag_group'] },
      'bin_assignments',
      'presentation_on_demand',
      {
        network: ['presentation_on_demand'],
        customized_time_ranges: ['time_range'],
        content_exclusions: ['media'],
        bin_assignments: ['media'],
        location_dialect_preferences: ['dialect'],
        custom_field_values: ['custom_field'],
      },
    ])
    .selectExtra({
      bin_assignments: ['bin_name', 'media_name', 'media_class'],
      locations: [
        'can_update',
        'can_delete',
        'updated_by_name',
        'all_presentations',
        'operating_hours_timeblock_effective',
        'operating_hours_timeblock_inherited',
        'operating_hours_timeblock_inherited_from',
        'download_hours_timeblock_effective',
        'download_hours_timeblock_inherited',
        'download_hours_timeblock_inherited_from',
        'download_max_file_size_effective',
        'download_max_file_size_inherited',
        ...(includeCounts ? ['playerCount'] : []),
      ],
    })
    .find(id)
    .then(response => handleRecord<Location>(response))
    .catch(handleException)
}

export const loadOnlyNetwork = ({ id }: { id: string }) =>
  Network.find(id)
    .then(response => handleRecord<Network>(response))
    .catch(handleException)

export const loadNetwork = ({ id, includeCounts = false }: { id: string; includeCounts?: boolean }) => {
  let networks = Network.extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
    .includes([
      { tags: ['tag_group'] },
      'bin_assignments',
      'presentation_on_demand',
      'on_demand_title_logo_media',
      {
        customized_time_ranges: ['time_range'],
        content_exclusions: ['media'],
        bin_assignments: ['media'],
        network_dialect_preferences: ['dialect'],
        custom_field_values: ['custom_field'],
      },
    ])
    .selectExtra({
      bin_assignments: ['bin_name', 'media_name', 'media_class'],
      networks: [
        'all_presentations',
        'can_delete',
        'can_update',
        'updated_by_name',
        'all_presentations',
        'operating_hours_timeblock_effective',
        'operating_hours_timeblock_inherited',
        'operating_hours_timeblock_inherited_from',
        'download_hours_timeblock_effective',
        'download_hours_timeblock_inherited',
        'download_hours_timeblock_inherited_from',
        'download_max_file_size_effective',
        'download_max_file_size_inherited',
        ...(includeCounts ? ['location_count', 'player_count'] : []),
      ],
    })

  return networks
    .find(id)
    .then(response => handleRecord<Network>(response))
    .catch(handleException)
}

export const loadMultiDayLogs = ({
  id,
  whereField,
  filter,
  perPage,
  page,
  startDate,
  endDate,
  startHourMinute,
  endHourMinute,
  targetType,
  event = 'all',
  includeStats = false,
}: {
  id: string
  whereField: string
  filter: string
  perPage: number
  page: number
  startDate: string
  endDate: string
  startHourMinute: TimeDropDownHourMinute
  endHourMinute: TimeDropDownHourMinute
  targetType: 'player' | 'location' | 'network'
  event: string
  includeStats?: boolean
}) => {
  let baseQuery = Log.includes([{ media: ['customer'] }, 'player'])
    .where({ [whereField]: id })
    .where({
      time_range_multi_day: { start_date: startDate, end_date: endDate, start: startHourMinute, end: endHourMinute },
    })

  if (includeStats) {
    baseQuery = baseQuery.stats({ total: 'count' })
  }

  if (filter !== '') {
    baseQuery = baseQuery.where({ filter })
  }

  if (event !== 'all') {
    baseQuery = baseQuery.where({ event })
  }

  if (targetType === 'location') {
    baseQuery = baseQuery.selectExtra(['playerName'])
  }

  if (targetType === 'network') {
    baseQuery = baseQuery.selectExtra(['locationName', 'playerName'])
  }

  return baseQuery
    .order({ playerLocaltime: 'asc' })
    .per(perPage)
    .page(page)
    .all()
    .then(response => dataAndCountPromise<Log>(response))
}

export const loadLogs = ({
  id,
  whereField,
  filter,
  perPage,
  page,
  date,
  startHourMinute,
  endHourMinute,
  event = 'all',
}: {
  id: string
  whereField: string
  filter: string
  perPage: number
  page: number
  date: string
  startHourMinute: TimeDropDownHourMinute
  endHourMinute: TimeDropDownHourMinute
  event: string
}) => {
  let baseQuery = Log.stats({ total: 'count' })
    .includes([{ media: ['customer'] }, 'player'])
    .where({ date, [whereField]: id })
    .where({ time_range: { date, start: startHourMinute, end: endHourMinute } })

  if (filter !== '') {
    baseQuery = baseQuery.where({ filter })
  }

  if (event !== 'all') {
    baseQuery = baseQuery.where({ event })
  }

  return baseQuery
    .order({ playerLocaltime: 'asc' })
    .per(perPage)
    .page(page)
    .all()
    .then(response => dataAndCountPromise<Log>(response))
}

export const loadPlayer = ({ id }: { id: string }) =>
  Player.includes([
    'bin_assignments',
    'location',
    'network',
    'presentation_on_demand',
    'display_configuration',
    {
      tags: ['tag_group'],
      location: 'presentation_on_demand',
      network: 'presentation_on_demand',
      customized_time_ranges: ['time_range'],
      content_exclusions: ['media'],
      bin_assignments: ['media'],
      player_dialect_preferences: ['dialect'],
      custom_field_values: ['custom_field'],
      video_outputs: ['video_output_resolutions'],
    },
  ])
    .selectExtra({
      bin_assignments: ['bin_name', 'media_name', 'media_class'],
      players: [
        'all_presentations',
        'operating_hours_timeblock_effective',
        'operating_hours_timeblock_inherited',
        'operating_hours_timeblock_inherited_from',
        'download_hours_timeblock_effective',
        'download_hours_timeblock_inherited',
        'download_hours_timeblock_inherited_from',
        'download_max_file_size_effective',
        'download_max_file_size_inherited',
        'display_source_name',
        'player_group_ids',
        'player_group_ids_with_on_demand',
        'display_configuration_object',
        'media_partition_bytes_total',
        'screenshot_url',
        'screenshot_timestamp',
        'tunnel_pending',
        'tunnel_port',
        'location_name',
        'network_name',
        'can_update',
        'can_delete',
      ],
    })
    .extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
    .find(id)
    .then(response => handleRecord<Player>(response))
    .catch(handleException)

export const savePlayer = ({
  player,
  handleApiResponse,
}: {
  player: Player
  handleApiResponse: HandleApiResponse<Player>
}) => {
  player
    .save({
      with: [
        'binAssignments',
        'customFieldValues',
        'playerDialectPreferences',
        'contentExclusions',
        'customizedTimeRanges',
      ],
    })
    .then(success => handleApiResponse(success, player))
    .catch(handleException)
}

export const destroyPlayer = ({
  player,
  handleApiResponse,
}: {
  player: Player
  handleApiResponse: HandleApiResponse<Player>
}) => {
  player
    .destroy()
    .then(success => handleApiResponse(success, player))
    .catch(handleException)
}

export const refreshPlayer = ({ id }: { id: string }) =>
  Player.includes(['directory_files', 'assigned_files'])
    .find(id)
    .then(response => handleRecord<Player>(response))
    .catch(handleException)

export const loadTimeRanges = () => TimeRange.all().then(response => dataAndCountPromise<TimeRange>(response))

export const loadBins = () =>
  Media.where({ type: 'bin' })
    .all()
    .then(response => dataAndCountPromise<Media>(response))

export const loadDisplayVendors = () =>
  DisplayModelVendor.includes([{ display_drivers: ['display_models', 'display_model_sources'] }])
    .all()
    .then(response => dataAndCountPromise<DisplayModelVendor>(response))

export const newContentExclusion = ({
  // TODO: Rename this to hint that it is a ModelSelectorModel
  media,
  excluder,
}: {
  media: ModelSelectorModel
  excluder: { id?: string; key: () => string; modelClassName?: string }
}) => {
  const exclusion = new ContentExclusion({
    modelClassName: 'ContentExclusion',
    mediaId: media.id,
    excluderType: excluder.modelClassName,
    excluderId: excluder.key(),
    canUpdate: true,
  })

  exclusion.media = new Media({ id: media.id, modelClassName: media.class, name: media.name, canUpdate: true })
  exclusion.temp_id = dummyIdGenerator()

  return exclusion
}

export const setActivationStatus = ({ player, status }: { player: Player; status: boolean }) => {
  const updatedPlayer = new Player({ id: player.id, canUpdate: true })

  updatedPlayer.isPersisted = true
  updatedPlayer.isActive = status

  return updatedPlayer.save()
}

export const newCustomizedTimeRange = ({ timeRange }: { timeRange: { id: string; name: string } }) => {
  const existingTimeRange = new TimeRange()
  existingTimeRange.modelClassName = 'TimeRange'
  existingTimeRange.id = timeRange.id
  existingTimeRange.name = timeRange.name
  existingTimeRange.isPersisted = true
  existingTimeRange.canUpdate = true

  const customizedTimeRange = new CustomizedTimeRange()
  customizedTimeRange.timeRangeId = timeRange.id
  customizedTimeRange.timeRange = existingTimeRange
  customizedTimeRange.canUpdate = true

  return customizedTimeRange
}

export const newBinAssignment = ({
  binId,
  mediaId,
  mediaName,
  mediaClass,
  distributableId,
  distributableType,
}: {
  binId: string
  mediaId: string
  mediaName: string
  mediaClass: string
  distributableId: string
  distributableType: string
}) => {
  const binAssignment = new BinAssignment({
    binId,
    distributableId,
    distributableType,
    mediaId,
  })

  binAssignment.modelClassName = 'BinAssignment'
  binAssignment.media = new Media({ id: mediaId, name: mediaName, modelClassName: mediaClass })
  binAssignment._mediaName = mediaName
  binAssignment._mediaClass = mediaClass
  binAssignment.temp_id = dummyIdGenerator()
  binAssignment.canUpdate = true

  return binAssignment
}

export const loadOneSmartGroup = ({ id }: { id: string }) =>
  SmartGroup.extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
    .includes(['smart_group_conditions'])
    .selectExtra(['allPresentations', 'can_update', 'can_delete', 'updated_by_name'])
    .find(id)
    .then(response => handleRecord<SmartGroup>(response))
    .catch(handleException)

export const loadSmartGroup = ({ id, type }: { id: string; type: SmartGroupCapableModels }) => {
  if (id === 'new') {
    const smartGroup = new SmartGroup({ joinType: 'OR', canUpdate: true })
    const firstEmptyCondition = new SmartGroupCondition()
    firstEmptyCondition.temp_id = dummyIdGenerator()
    firstEmptyCondition.canUpdate = true

    smartGroup.smartGroupConditions = [firstEmptyCondition]
    smartGroup.type = `${type}SmartGroup`

    return Promise.resolve<[SmartGroup, number]>([smartGroup, 1])
  }

  return loadOneSmartGroup({ id })
}

export const saveSmartGroup = ({
  smartGroup,
  handleApiResponse,
}: {
  smartGroup: SmartGroup
  handleApiResponse: HandleApiResponse<SmartGroup>
}) => {
  smartGroup
    .save({ with: ['smartGroupConditions'] })
    .then(success => {
      handleApiResponse(success, smartGroup)
    })
    .catch(handleException)
}

export const duplicateSmartGroup = ({
  smartGroup,
  onDuplicate,
}: {
  smartGroup: SmartGroup
  // TODO: Research this type
  onDuplicate: (id: string | false | undefined) => void
}) => {
  smartGroup.duplicate().then(newGroupId => {
    onDuplicate(newGroupId)
  })
}

export const destroySmartGroup = ({
  smartGroup,
  handleApiResponse,
}: {
  smartGroup: SmartGroup
  handleApiResponse: HandleApiResponse<SmartGroup>
}) => {
  smartGroup
    .destroy()
    .then(success => {
      handleApiResponse(success, smartGroup)
    })
    .catch(handleException)
}

export const newSmartGroupCondition = () => {
  const newCondition = new SmartGroupCondition()
  newCondition.modelClassName = 'SmartGroupCondition'
  newCondition.temp_id = dummyIdGenerator()
  newCondition.canUpdate = true

  return newCondition
}

export const loadTagGroups = () =>
  TagGroup.order('name')
    .all()
    .then(response => dataAndCountPromise<TagGroup>(response))

export const loadTags = () => {
  return Tag.order('name')
    .all()
    .then(response => dataAndCountPromise<Tag>(response))
}

export const playerCountMatchingDisplayConfiguration = async ({
  screenResolutionX,
  screenResolutionY,
  screenArrayX,
  screenArrayY,
  orientation,
}: {
  screenResolutionX: number | null
  screenResolutionY: number | null
  screenArrayX: number | null
  screenArrayY: number | null
  orientation: string | null
}) => {
  const displayConfigurations = await DisplayConfiguration.where({
    screenResolutionX,
    screenResolutionY,
    screenArrayX,
    screenArrayY,
    orientation: orientation?.toUpperCase(),
  })
    .select(['id'])
    .all()

  const ids = displayConfigurations.data.map(({ id }) => id)

  const players = await Player.where({ displayConfigurationId: ids }).select(['id']).stats({ total: 'count' }).all()

  return players.meta.stats.total.count as number
}

export const loadDisplayConfigurations = () => {
  return DisplayConfiguration.order('name')
    .all()
    .then(response => dataAndCountPromise<DisplayConfiguration>(response))
}

export const newTagging = ({
  tagId,
  taggableObject,
  taggableType,
}: {
  tagId: string
  taggableObject: { id: string }
  taggableType: string
}) => {
  const newTagging = new Tagging()
  newTagging.modelClassName = 'Tagging'
  newTagging.tagId = tagId
  newTagging.taggableId = taggableObject.id
  newTagging.taggableType = taggableType
  newTagging.canUpdate = true

  return newTagging
}

export const loadPlayerGroups = ({ ids }: { ids: string[] }) => {
  return (
    PlayerGroup.selectExtra(['updated_by_name'])
      .extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
      .includes(['presentation_on_demand'])
      // Hack to ensure that we at least have some filter here
      .where({ id: [0, ...ids] })
      .all()
      .then(response => dataAndCountPromise<PlayerGroup>(response))
  )
}

export const loadOnePlayerGroup = ({ id }: { id: string }) =>
  PlayerGroup.selectExtra(['all_presentations', 'can_update', 'can_delete', 'updated_by_name'])
    .includes([
      'presentation_on_demand',
      { content_exclusions: ['media'], player_distribution: ['networks', 'players', 'locations', 'smart_groups'] },
    ])
    .find(id)
    .then(response => handleRecord<PlayerGroup>(response))
    .catch(handleException)

export const loadPlayerGroupByIdOrNew = ({ id }: { id: string }) => {
  if (id === 'new') {
    const playerDistribution = new PlayerDistribution({ players: [], locations: [], networks: [], smartGroups: [] })
    const playerGroup = new PlayerGroup({ playerDistribution })
    playerGroup.canUpdate = true

    return Promise.resolve<[PlayerGroup, number]>([playerGroup, 1])
  }

  return loadOnePlayerGroup({ id })
}

export const savePlayerGroup = ({
  playerGroup,
  handleApiResponse,
}: {
  playerGroup: PlayerGroup
  handleApiResponse: HandleApiResponse<PlayerGroup>
}) => {
  playerGroup
    .save({ with: [{ playerDistribution: ['networks', 'players', 'locations', 'smartGroups'] }, 'contentExclusions'] })
    .then(success => {
      handleApiResponse(success, playerGroup)
    })
}

export const destroyPlayerGroup = ({
  playerGroup,
  handleApiResponse,
}: {
  playerGroup: PlayerGroup
  handleApiResponse: HandleApiResponse<PlayerGroup>
}) => {
  playerGroup
    .destroy()
    .then(success => {
      handleApiResponse(success, playerGroup)
    })
    .catch(handleException)
}

export const duplicatePlayerGroup = ({
  playerGroup,
  onDuplicate,
}: {
  playerGroup: PlayerGroup
  onDuplicate: (id: string | false | undefined) => void
}) => {
  playerGroup.duplicate().then(newGroupId => {
    onDuplicate(newGroupId)
  })
}

export const newPlayer = ({ data }: { data: Record<string, any> }) => {
  const player = new Player(data)
  player.canUpdate = true
  player.isPersisted = true

  return player
}

export const buildCustomFieldValue = ({
  model,
  customField,
}: {
  model: CustomFieldValueCapable
  customField: CustomField
}) => {
  const newCustomFieldValue = new CustomFieldValue()
  newCustomFieldValue.modelClassName = 'CustomFieldValue'
  newCustomFieldValue.temp_id = dummyIdGenerator()
  newCustomFieldValue.model = model
  newCustomFieldValue.modelId = model.key()
  newCustomFieldValue.modelType = model.modelClassName
  newCustomFieldValue.customFieldId = customField.id
  newCustomFieldValue.customField = customField
  newCustomFieldValue.canUpdate = true

  return newCustomFieldValue
}

export const buildExistingLocation = ({ data }: { data: Record<string, any> }) => {
  const location = new Location(data)
  location.canUpdate = true
  location.isPersisted = true

  return location
}

export const newLocation = () => new Location({ modelClassName: 'Location', temp_id: dummyIdGenerator() })

export const newNetwork = () => {
  const network = new Network({
    temp_id: dummyIdGenerator(),
    modelClassName: 'Network',
    operatingHoursTimeblock: fullTimeBlock,
    downloadHoursTimeblock: emptyTimeBlock,
    canUpdate: true,
  })

  return network
}

export const buildExistingNetwork = ({ data }: { data: Record<string, any> }) => {
  const network = new Network(data)
  network.canUpdate = true
  network.isPersisted = true

  return network
}

export const newSmartGroup = ({ data }: { data: Record<string, any> }) => {
  const smartGroup = new SmartGroup(data)
  smartGroup.modelClassName = 'SmartGroup'
  smartGroup.isPersisted = true
  smartGroup.canUpdate = true

  return smartGroup
}

export const deleteTag = ({ tag }: { tag: Tag }) => {
  return tag.destroy()
}

export const buildTag = ({ tagGroup, name }: { tagGroup: TagGroup; name: string }) => {
  const tag = new Tag()
  tag.temp_id = dummyIdGenerator()
  tag.tagGroup = tagGroup
  tag.name = name
  tag.canUpdate = true

  return tag
}

export const buildTagGroup = ({ tagGroupName }: { tagGroupName: string }) => {
  const tagGroup = new TagGroup()
  tagGroup.name = tagGroupName
  tagGroup.temp_id = dummyIdGenerator()
  tagGroup.canUpdate = true

  return tagGroup
}

export const buildCustomField = ({ model }: { model: string }) => {
  const customField = new CustomField()
  customField.model = model
  customField.temp_id = dummyIdGenerator()
  customField.canUpdate = true

  return customField
}

export const EMPTY_TIME_BLOCK =
  'mo:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,tu:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,we:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,th:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,fr:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,sa:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000,su:15:000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'

export const buildTimeRange = () => {
  const timeRange = new TimeRange()
  timeRange.name = ''
  timeRange.timeblock = EMPTY_TIME_BLOCK
  timeRange.temp_id = dummyIdGenerator()
  timeRange.canUpdate = true

  return timeRange
}

export const saveLocation = ({
  location,
  handleApiResponse,
}: {
  location: Location
  handleApiResponse: HandleApiResponse<Location>
}) => {
  location
    .save({
      with: [
        'binAssignments',
        'customFieldValues',
        'networkDialectPreferences',
        'contentExclusions',
        'customizedTimeRanges',
      ],
    })
    .then(success => handleApiResponse(success, location))
    .catch(handleException)
}

export const saveNetwork = ({
  network,
  handleApiResponse,
}: {
  network: Network
  handleApiResponse: HandleApiResponse<Network>
}) => {
  network
    .save({
      with: [
        'binAssignments',
        'customFieldValues',
        'networkDialectPreferences',
        'contentExclusions',
        'customizedTimeRanges',
      ],
    })
    .then(success => handleApiResponse(success, network))
    .catch(handleException)
}

export const destroyLocation = ({
  location,
  handleApiResponse,
}: {
  location: { id?: string }
  handleApiResponse: HandleApiResponse<Location>
}) => {
  const locationObject = new Location({ id: location.id, isPersisted: true })

  locationObject
    .destroy()
    .then(success => handleApiResponse(success, locationObject))
    .catch(handleException)
}

export const destroyNetwork = ({
  network,
  handleApiResponse,
}: {
  network: Network
  handleApiResponse: HandleApiResponse<Network>
}) => {
  network
    .destroy()
    .then(success => handleApiResponse(success, network))
    .catch(handleException)
}

export const newNetworkContentExclusion = ({ media, network }: { media: ModelSelectorModel; network: Network }) => {
  const exclusion = new ContentExclusion({
    mediaId: media.id,
    excluderType: 'Network',
    excluderId: network.id,
    canUpdate: true,
  })

  exclusion.modelClassName = 'ContentExclusion'
  exclusion._media = { ...media, type: media.class, isPersisted: true }
  exclusion.temp_id = dummyIdGenerator()

  return exclusion
}

export const newNetworkBinAssignment = ({
  network,
  options,
}: {
  network: Network
  options: { binId: string; id: string; name: string; type: string }
}) => {
  const binAssignment = new BinAssignment({
    binId: options.binId,
    distributableId: network.id,
    distributableType: 'Network',
    mediaId: options.id,
    canUpdate: true,
  })

  binAssignment.modelClassName = 'BinAssignment'
  binAssignment._mediaName = options.name
  binAssignment._mediaClass = options.type
  binAssignment.temp_id = dummyIdGenerator()

  return binAssignment
}

export const loadOneGroupCategory = ({ id }: { id: string }) =>
  GroupCategory.selectExtra(['smart_group_count', 'media_group_count'])
    .find(id)
    .then(response => handleRecord<GroupCategory>(response))
    .catch(handleException)

export function loadMediaGroupsForIds({ ids }: { ids: string[] }) {
  return MediaGroup.where({ id: ids })
    .all()
    .then(response => dataAndCountPromise<MediaGroup>(response))
    .catch(handleException)
}

export function loadPlayerGroupsForIds({ ids }: { ids: string[] }) {
  return PlayerGroup.where({ id: ids })
    .all()
    .then(response => dataAndCountPromise<PlayerGroup>(response))
    .catch(handleException)
}

export function loadSmartGroupsForIds({ ids }: { ids: string[] }) {
  return SmartGroup.where({ id: ids })
    .all()
    .then(response => dataAndCountPromise<SmartGroup>(response))
    .catch(handleException)
}

export const loadOneMediaGroup = ({ id }: { id: string }) =>
  MediaGroup.extraFetchOptions({ headers: X_NO_CLIENT_TIMEOUT_REFRESH_HEADER })
    .includes([{ media_group_items: 'media' }])
    .selectExtra({ media_groups: ['allPresentations', 'can_update', 'can_delete', 'updated_by_name'] })
    .find(id)
    .then(response => handleRecord<MediaGroup>(response))
    .catch(handleException)

export const loadMediaGroup = ({ id }: { id: string }) =>
  id === 'new' ? Promise.resolve<[MediaGroup, number]>([new MediaGroup(), 1]) : loadOneMediaGroup({ id })

export const destroyMediaGroup = ({
  mediaGroup,
  handleApiResponse,
}: {
  mediaGroup: MediaGroup
  handleApiResponse: HandleApiResponse<MediaGroup>
}) => {
  mediaGroup.destroy().then(success => {
    handleApiResponse(success, mediaGroup)
  })
}

export const saveMediaGroup = ({
  mediaGroup,
  handleApiResponse,
}: {
  mediaGroup: MediaGroup
  handleApiResponse: HandleApiResponse<MediaGroup>
}) => {
  mediaGroup.save({ with: ['mediaGroupItems'] }).then(success => {
    handleApiResponse(success, mediaGroup)
  })
}

export const duplicatePresentation = async ({
  id,
  name,
  copyContent,
}: {
  id: string
  name: string
  copyContent: boolean
}) => {
  const newPresentation = new DuplicatePresentation({ id, name, copyContent })

  const success = await newPresentation.save()
  if (success) {
    return { id: newPresentation.key(), ok: true }
  } else {
    return { ok: false, id: '0' }
  }
}

export const duplicateMediaGroup = ({
  mediaGroup,
  onDuplicate,
}: {
  mediaGroup: MediaGroup
  onDuplicate: (id: string | false | undefined) => void
}) => {
  mediaGroup.duplicate().then(newGroupId => {
    onDuplicate(newGroupId)
  })
}

export const newMediaGroupItem = ({ model }: { model: ModelSelectorModel }) => {
  const media = new Media({ ...model, type: model.polymorphic_class, canUpdate: true })
  const item = new MediaGroupItem({ media })

  item.canUpdate = true
  item.modelClassName = 'MediaGroupItem'
  item.mediaId = media.id
  item.temp_id = dummyIdGenerator()

  return item
}

export const saveReportRequest = (reportRequest: ReportRequest) => {
  return reportRequest
    .save()
    .then(success => Promise.resolve([success, reportRequest]))
    .catch(handleException)
}

export const submitReportRequest = ({
  targetType,
  targetId,
  reportClassName,
  parameters,
}: {
  targetType: string
  targetId: string
  reportClassName: string
  parameters: object
}): Promise<[boolean, ReportRequest]> => {
  const reportRequest = new ReportRequest()

  reportRequest.canUpdate = true
  reportRequest.targetType = targetType
  reportRequest.targetId = targetId
  reportRequest.reportClassName = reportClassName
  reportRequest.parameters = parameters

  return reportRequest.save().then(success => Promise.resolve([success, reportRequest]))
}

export const submitResetPassword = ({
  token,
  password,
  passwordConfirmation,
}: {
  token: string
  password: string
  passwordConfirmation: string
}) => {
  const resetPassword = new ResetPassword()
  resetPassword.token = token
  resetPassword.password = password
  resetPassword.passwordConfirmation = passwordConfirmation

  return resetPassword.save().then(() => {
    return Promise.resolve()
  })
}

export const submitResetPasswordRequest = ({ email }: { email: string }) => {
  const resetRequest = new ResetPasswordRequest()
  resetRequest.email = email

  return resetRequest.save().then(() => {
    return Promise.resolve()
  })
}

export const submitLocationBulkAction = ({ ids, request }: { ids: string[]; request: object }) => {
  const bulkAction = new LocationBulkAction()
  bulkAction.ids = ids
  bulkAction.request = request

  return bulkAction.save().then(() => {
    return Promise.resolve(bulkAction.response)
  })
}

export const submitNetworkBulkAction = ({ ids, request }: { ids: string[]; request: object }) => {
  const bulkAction = new NetworkBulkAction()
  bulkAction.ids = ids
  bulkAction.request = request

  return bulkAction.save().then(() => {
    return Promise.resolve(bulkAction.response)
  })
}

export const submitPlayerBulkAction = ({ ids, request }: { ids: string[]; request: object }) => {
  const bulkAction = new PlayerBulkAction()
  bulkAction.ids = ids
  bulkAction.request = request

  return bulkAction.save().then(() => {
    return Promise.resolve(bulkAction.response)
  })
}

export const submitMediaBulkAction = ({ ids, request }: { ids: string[]; request: object }) => {
  const bulkAction = new MediaBulkAction()
  bulkAction.ids = ids
  bulkAction.request = request

  return bulkAction.save().then(() => {
    return Promise.resolve(bulkAction.response)
  })
}

export const convertObjectToPersistedSmartGroup = (object: Record<string, any>) => {
  const newGroup = new SmartGroup(object)
  newGroup.modelClassName = 'SmartGroup'
  newGroup.isPersisted = true
  newGroup.canUpdate = true

  return newGroup
}

export const convertObjectToPersistedStaticGroup = (
  object: Record<string, any>,
  className: 'Player' | 'Media' | 'Presentation'
) => {
  let newGroup

  switch (className) {
    case `Player`:
      newGroup = new PlayerGroup(object)
      break

    case `Media`:
      newGroup = new MediaGroup(object)
      break

    case 'Presentation':
      newGroup = new Presentation(object)
      break

    default:
      return null
  }
  newGroup.isPersisted = true
  newGroup.canUpdate = true

  return newGroup
}

export const savePreference = ({ preference }: { preference: Preference }) => {
  return preference.save()
}
